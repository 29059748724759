import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MetaTags from '../components/common/MetaTags';
import HeroSection from '../components/Donate/HeroSection';
import DonorsFoundations from '../components/common/DonorsFoundations';
import MainTitle from '../components/common/MainTitle';
import LeadershipSkills from '../components/Home/LeadershipSkills';
import BannersSection from '../components/Donate/BannersSection';
import scrollToTarget from '../js/utils/scroll';

export default function DonatePage() {
  const { t } = useTranslation(['DonatePage']);

  // Scroll to the top after the page is loaded
  // This is mainly added to fix page view after redirect, the user views the page bottom onload
  useEffect(() => {
    const element = document?.getElementById('donate-page');
    scrollToTarget(element);
  }, []);

  return (
    <main className="donate-page" id="donate-page">
      <MetaTags title={t('pageTitle')} />
      <HeroSection />
      <LeadershipSkills />
      <DonorsFoundations>
        <MainTitle type="dark" className="text--2xl">
          <span>{t('foundationsTitle1')}</span>
          <span className="decorated-line decorated-line--dark decorated-line--left">
            {t('foundationsTitle2')}
          </span>
          <span className="title2">{t('foundationsTitle3')}</span>
        </MainTitle>

        <div className="donors-foundations__description">
          <p className="description">{t('foundationsDescription1')}</p>
          <p className="description">{t('foundationsDescription2')}</p>
        </div>
      </DonorsFoundations>

      <BannersSection />
    </main>
  );
}
