import { ENDPOINTS } from './endpoints';
import { bindLocaleIntoHeaders } from '../utils/resolve-locale';
import { fetchData } from './network';

export function getDonate() {
  const url = new URL(`${process.env.API_URL}${ENDPOINTS.getDonateFoundations.path}`);
  bindLocaleIntoHeaders(url);
  url.searchParams.append('populate', '*');

  return fetchData(url, {
    method: ENDPOINTS.getDonateFoundations.method
  }).then((response) => response);
}
