import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LocaleContext from '../../contexts/LocaleContext';

import PageCTA from '../common/PageCTA';
import AppButton from '../common/AppButton';

import AboutOurMission from '../../assets/images/donate/donate-hero-image-left.jpg';
import AboutOurMissionWebp from '../../assets/images/donate/donate-hero-image-left.webp';
import AcademicStructure from '../../assets/images/donate/donate-hero-image-right.jpg';
import AcademicStructureWebp from '../../assets/images/donate/donate-hero-image-right.webp';

export default function BannersSection() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['DonatePage']);
  return (
    <section className="banners-container">
      <PageCTA
        title1={t('ourMissionTitle1')}
        title2={t('ourMissionTitle2')}
        image={AboutOurMission}
        imageWebp={AboutOurMissionWebp}
        decoratedLine="right"
        alt="Join Manara Team">
        <AppButton className="donate-now" theme="light" tag="a" linkPath={`/${locale}/about`}>
          {t('ourMissionLink')}
        </AppButton>
      </PageCTA>

      <PageCTA
        title1={t('academicStructureTitle1')}
        title2={t('academicStructureTitle2')}
        image={AcademicStructure}
        imageWebp={AcademicStructureWebp}
        decoratedLine="right"
        alt="Join Manara Team">
        <AppButton className="donate-now" theme="light" tag="a" linkPath={`/${locale}/academics`}>
          {t('academicStructureLink')}
        </AppButton>
      </PageCTA>
    </section>
  );
}
