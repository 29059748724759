/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import MaximizingLeadership from '../components/Home/MaximizingLeadership';
import StudentsLeadershipPotential from '../components/Home/StudentsLeadershipPotential';
import Accordion from '../components/Accordion/Accordion';
import ArticleGrid from '../components/Articles/ArticleGrid';
import CollegePrepSlider from '../components/CollegePrepSlider/CollegePrepSlider';
import Experiences from '../components/Home/Experiences';
import DonorsFoundations from '../components/common/DonorsFoundations';
import MainTitle from '../components/common/MainTitle';
import ScholarshipProgram from '../components/Home/ScholarshipProgram';
import HeroImage from '../components/Home/HeroImage';
import ApplicationDetails from '../components/ApplicationDetails';
// import AdmissionsOpenNotification from '../components/Home/AdmissionsOpenNotification';
import MetaTags from '../components/common/MetaTags';
import AppButton from '../components/common/AppButton';

import { getHomePage } from '../js/api/homepage';
import HomeData from '../models/HomePageData';
import LocaleContext from '../contexts/LocaleContext';

export default function HomePage() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);
  const [homePageData, setHomePageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Handle fetching homepage data.
  useEffect(() => {
    setIsLoading(true);
    getHomePage()
      .then((res) => {
        setHomePageData(new HomeData(res.data));
      })
      .catch((e) => {
        toast.error('An error occurred, try again later');
        throw e;
      })
      .finally(() => setIsLoading(false));
  }, [locale]);

  return (
    <main className="homepage">
      {!isLoading && homePageData ? (
        <>
          <MetaTags title={t('pageTitle')} seo={homePageData?.metaTags} />
          <HeroImage {...homePageData?.heroSection} />
          <MaximizingLeadership data={homePageData?.maximizingLeadership} />
          <StudentsLeadershipPotential />
          <CollegePrepSlider slides={homePageData?.prepSliderItems} />
          <div className="manara-education container">
            <p className="manara-education__content">
              {t('manaraEducationPart1')}
              <span>{t('manaraEducationPart2')}</span>
            </p>
          </div>
          <Accordion data={homePageData?.accordionData} />
          <ScholarshipProgram />
          <ArticleGrid />
          <ApplicationDetails />
          {/* TODO: Enable the section when E-mail subscription service is ready */}
          {/* <AdmissionsOpenNotification /> */}
          <DonorsFoundations>
            <MainTitle type="dark" className="text--2xl">
              <span className="decorated-line decorated-line--dark decorated-line--right">
                {t('donationsTitle1')}
              </span>
              <span>{t('donationsTitle2')}</span>
            </MainTitle>
            <div className="donors-foundations__description">
              <p className="description">{t('donationsDesc1')}</p>
              <p className="description">{t('donationsDesc2')}</p>
            </div>

            <AppButton className="donate-now" tag="a" linkPath={`/${locale}/donate`}>
              {t('donateNow')}
            </AppButton>
          </DonorsFoundations>
          <Experiences />
        </>
      ) : null}
    </main>
  );
}
