import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import LocaleContext from '../../contexts/LocaleContext';
import { getFooter } from '../../js/api/navigation';

export default function FooterNav() {
  const { locale } = useContext(LocaleContext);
  const { t } = useTranslation(['HomePage']);
  const [footerItems, setFooterItems] = useState();

  /**
   * Handle fetching navigation data.
   */
  useEffect(() => {
    getFooter()
      .then((res) => {
        setFooterItems(res.sort((a, b) => a.order - b.order));
      })
      .catch((e) => {
        toast.error(t('getFooterLinksError'));
        throw e;
      });
  }, [locale]);

  const footerNavigationLinksJSX = footerItems?.map((item) => (
    <li key={`navigation-list__item-${item.id}`} className="navigation-list__item">
      {item.external ? (
        <a href={item.path} title={item.title}>
          {item.title}
        </a>
      ) : (
        <Link to={`${locale}${item.path}`} title={item.title}>
          {item.title}
        </Link>
      )}
    </li>
  ));

  return (
    <nav className="navigation container">
      <ul className="navigation-list">{footerNavigationLinksJSX}</ul>
    </nav>
  );
}
