{
  "pageTitle": "Donate Now",
  "weBelieve": "We believe that for",
  "everyTalented": "every talented student,",
  "anOpportunity": "there is an opportunity",
  "foundationsTitle1": "We’re supported by",
  "foundationsTitle2": "international non",
  "foundationsTitle3": "-profit foundations",
  "foundationsDescription1": "We are providing an opportunity for donors to transform children's lives who show great academic and leadership potential by giving them access to a first-rate education.",
  "foundationsDescription2": "Manara school donors can help level the playing field for less privileged kids in Egypt by providing the advantage of a college preparatory program that will set them up for success in university and beyond.",
  "donateNow": "Donate Now",
  "donationsError": "Failed to get donations data",
  "ourMissionTitle1": "Maximizing leadership potential to develop",
  "ourMissionTitle2": "our society’s future leaders",
  "ourMissionLink": "About Our Mission",
  "academicStructureTitle1": "We support an international College-Prep",
  "academicStructureTitle2": "Curriculum with Room for Exploration",
  "academicStructureLink": "Academic Structure"
}
